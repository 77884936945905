import productThumbnails from "../images/products/*.webp";

const MODAL_ELEMENT = $(".cq-modal.modal");
const MODAL_CONTENT_ELEMENT = $(".cq-modal.modal .modal-content");
const MODAL_CLOSE_ELEMENT = $(".cq-modal.modal .modal-close");
const PRODUCT_MODAL_BODY = {
  1: "<p>While our new website is being constructed, you will be taken to the original CIVCO RT website for the products and information you are seeking.</p><p>CIVCO RT and Qfix are now CQ Medical, the new global leader in patient radiotherapy positioning and healthcare innovations that advance human care.</p>",
  2: "<p>While our new website is being constructed, you will be taken to the original Qfix website for the products and information you are seeking.</p><p>CIVCO RT and Qfix are now CQ Medical, the new global leader in patient radiotherapy positioning and healthcare innovations that advance human care.</p>",
  3: "<p>While our new website is being constructed, you will be taken to the original CIVCO RT and Qfix website for the products and information you are seeking.</p><p>CIVCO RT and Qfix are now CQ Medical, the new global leader in patient radiotherapy positioning and healthcare innovations that advance human care.</p>",
};
const PRODUCT_MODAL_HEADER = {
  1: "CIVCO RT™ is now <br> CQ Medical™",
  2: "Qfix® is now <br> CQ Medical™",
  3: "CIVCO RT™ and Qfix® are now CQ Medical™",
};
const firstLineProducts = [
  {
    id: "1",
    thumbnail: "head-neck-large@2x",
    title: "Encompass™ SRS Immobilization System",
    version: "2",
    buttons: [
      {
        title: "Continue to Qfix Site",
        href: "https://qfix.com/catalog/radiotherapy/srs-srt-stereotactic-radiosurgery",
      },
    ],
    instruction:
      "See more about the Encompass™ SRS Immobilization System at the below link.",
  },
  {
    id: "2",
    thumbnail: "product-img-02-lg@2x",
    title: "Body Pro-Lok ONE™ SBRT Immobilization System",
    version: "1",
    buttons: [
      {
        title: "Continue to CIVCO RT Site",
        href: "https://civcort.com/ro/Stereotactic-Body-Radiation-Therapy/Body-Pro-Lok-One1/Body-Pro-Lok-One.htm",
      },
    ],
    instruction: "See more about the Body Pro-Lok ONE™ at the below link.",
  },
  {
    id: "3",
    thumbnail: "product-img-03-large@2x",
    title: "Thermoplastics",
    version: "3",
    buttons: [
      {
        title: "Continue to CIVCO RT Site",
        href: "https://civcort.com/ro/products/Head-Neck-Positioning.htm",
      },
      {
        title: "Continue to Qfix Site",
        href: "https://qfix.com/catalog/radiotherapy/thermoplastics",
      },
    ],
    instruction:
      "See more about thermoplastics at the legacy CIVCO RT and Qfix links below.",
  },
  {
    id: "4",
    thumbnail: "product-img-symphony@2x",
    title: "Symphony® Imaging, Treatment & Transfer Solutions",
    version: "2",
    buttons: [
      {
        title: "Continue to Qfix Site",
        href: "https://qfix.com/catalog/patient-transfer",
      },
    ],
    instruction:
      "See more about the Symphony® Imaging, Treatment & Transfer Solutions at the below link.",
  },
];
const secondLineProducts = [
  {
    id: "5",
    thumbnail: "product-img-allinone@2x",
    title: "Alta™ Multipurpose Device",
    version: "3",
    buttons: [
      {
        title: "View product catalog (PDF)",
        href: "assets/pdfs/products_catalog/All-in-One-Solutions-CQ Medical.pdf",
      },
      {
        title: "Continue to CIVCO RT Site",
        href: "https://civcort.com/ro/products/TotalRT-All-in-One-Solution",
      },
      {
        title: "Continue to Qfix Site",
        href: "https://qfix.com/catalog/radiotherapy/multipurpose-solution",
      },
    ],
    instruction: "See the All-In-One Solutions products in the below PDF.",
  },
  {
    id: "6",
    thumbnail: "product-img-breast-thorax@2x",
    title: "C-Qual M™ Breastboard",
    version: "3",
    buttons: [
      {
        title: "View product catalog (PDF)",
        href: "assets/pdfs/products_catalog/Breast-Thorax-CQ Medical.pdf",
      },
      {
        title: "Continue to CIVCO RT Site",
        href: "https://civcort.com/ro/products/Breast-Positioning.htm",
      },
      {
        title: "Continue to Qfix Site",
        href: "https://qfix.com/catalog/radiotherapy/breast-boards-torso-solutions",
      },
    ],
    instruction: "See the Breast & Thorax products in the below PDF.",
  },
  {
    id: "7",
    thumbnail: "product-img-proton@2x",
    title: "ProForm™ Proton Overlay",
    version: "3",
    buttons: [
      {
        title: "View product catalog (PDF)",
        href: "assets/pdfs/products_catalog/ProtonSeries-CQ Medical.pdf",
      },
      {
        title: "Continue to CIVCO RT Site",
        href: "https://civcort.com/ro/products/Proton",
      },
      {
        title: "Continue to Qfix Site",
        href: "https://qfix.com/catalog/radiotherapy/proton-positioning",
      },
    ],
    instruction: "See the ProtonSeries® products in the below PDF.",
  },
  {
    id: "8",
    thumbnail: "product-img-fiducial@2x",
    title: "Fiducials",
    version: "3",
    buttons: [
      {
        title: "View product catalog (PDF)",
        href: "assets/pdfs/products_catalog/Fiducial-Markers-CQ Medical.pdf",
      },
      {
        title: "Continue to CIVCO RT Site",
        href: "https://civcort.com/ro/products/Fiducial-Markers.htm",
      },
      {
        title: "Continue to Qfix Site",
        href: "https://qfix.com/catalog/radiotherapy/markers",
      },
    ],
    instruction: "See the Fiducial Markers products in the below PDF.",
  },
];
function closePopup() {
  MODAL_ELEMENT.hide();
  $("body").css("overflow", "auto");
}
const renderDesktopCarouselItem = (product) => {
  return `
			<div class="item" onclick="onProductClick(${product.id})">
				<div class="product-card">
					<div class="box">
						<div class="title">${product.title}</div>
						<div>
							<img
								src="${productThumbnails[product.thumbnail]}"
								alt="Image"
								style="max-width: 100%"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>`;
};

function onProductClick(productId) {
  const selectedProduct = [...firstLineProducts, ...secondLineProducts].find(
    ({ id }) => id === productId.toString()
  );
  const version = selectedProduct.version;
  const modalContent = `
	<div class="modal-header text-uppercase">
		${PRODUCT_MODAL_HEADER[version]}
		<div class="highlight-line"></div>
	</div>
	<div class="modal-body">
		${PRODUCT_MODAL_BODY[version]}
		${selectedProduct.instruction}
	</div>
	<div class="modal-footer">
		${selectedProduct.buttons.reduce(
      (
        acc,
        button
      ) => `${acc}<a class="external-url" href="${button.href}" target="_blank"><div class="button" type="button">
			${button.title}
			<div class="arrow-icon"></div>
		</div></a>`,
      ""
    )}
	</div>`;
  MODAL_CONTENT_ELEMENT.empty();
  MODAL_CONTENT_ELEMENT.append(modalContent);
  MODAL_ELEMENT.css("display", "flex");
  $("body").css("overflow", "hidden");
}

$(document).ready(function () {
  if (window.location.hash !== null && window.location.hash !== "") {
    $("body").animate(
      {
        scrollTop: $(window.location.hash).offset().top,
      },
      3000
    );
  }
  [...firstLineProducts, ...secondLineProducts].forEach((product, index) => {
    $(".product_highlight_section .owl-carousel").append(
      `${renderDesktopCarouselItem(product)}`
    );
  });
  $(".owl-carousel").owlCarousel({
    autoplayTimeout: 1000 * 60 * 60 * 365,
    mouseDrag: false,
    touchDrag: false,
    responsiveBaseElement: "body",
    responsive: {
      0: {
        items: 1,
        mouseDrag: true,
        touchDrag: true,
      },

      600: {
        items: 4,
        mouseDrag: false,
        touchDrag: false,
      },
    },
  });
  $(document).mouseup(function (e) {
    if (MODAL_ELEMENT.has(e.target).length <= 0) {
      closePopup();
    }
  });
  window.onProductClick = onProductClick;
  window.closePopup = closePopup;
});
