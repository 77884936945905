const _temp0 = require("../images/products/head-neck-large@2x.webp");
const _temp1 = require("../images/products/iris-interventional-device@2x.webp");
const _temp2 = require("../images/products/iris-patient-C-arm-SCT@2x.webp");
const _temp3 = require("../images/products/product-img-02-lg@2x.webp");
const _temp4 = require("../images/products/product-img-03-large@2x.webp");
const _temp5 = require("../images/products/product-img-04-lg@2x.webp");
const _temp6 = require("../images/products/product-img-accessories@2x.webp");
const _temp7 = require("../images/products/product-img-allinone@2x.webp");
const _temp8 = require("../images/products/product-img-breast-board@2x.webp");
const _temp9 = require("../images/products/product-img-breast-thorax@2x.webp");
const _temp10 = require("../images/products/product-img-couchtop-med@2x.webp");
const _temp11 = require("../images/products/product-img-fiducial@2x.webp");
const _temp12 = require("../images/products/product-img-head-neck-thermo@2x.webp");
const _temp13 = require("../images/products/product-img-hip-pelvis@2x.webp");
const _temp14 = require("../images/products/product-img-marking@2x.webp");
const _temp15 = require("../images/products/product-img-mr@2x.webp");
const _temp16 = require("../images/products/product-img-nurofit-cushion@2x.webp");
const _temp17 = require("../images/products/product-img-nurofit-premiere@2x.webp");
const _temp18 = require("../images/products/product-img-proton@2x.webp");
const _temp19 = require("../images/products/product-img-sbrt@2x.webp");
const _temp20 = require("../images/products/product-img-srs@2x.webp");
const _temp21 = require("../images/products/product-img-symphony@2x.webp");
const _temp22 = require("../images/products/product-img-vacuum-cushions@2x.webp");
const _temp23 = require("../images/products/symphony-slate-device@2x.webp");
module.exports = {
  "head-neck-large@2x": _temp0,
  "iris-interventional-device@2x": _temp1,
  "iris-patient-C-arm-SCT@2x": _temp2,
  "product-img-02-lg@2x": _temp3,
  "product-img-03-large@2x": _temp4,
  "product-img-04-lg@2x": _temp5,
  "product-img-accessories@2x": _temp6,
  "product-img-allinone@2x": _temp7,
  "product-img-breast-board@2x": _temp8,
  "product-img-breast-thorax@2x": _temp9,
  "product-img-couchtop-med@2x": _temp10,
  "product-img-fiducial@2x": _temp11,
  "product-img-head-neck-thermo@2x": _temp12,
  "product-img-hip-pelvis@2x": _temp13,
  "product-img-marking@2x": _temp14,
  "product-img-mr@2x": _temp15,
  "product-img-nurofit-cushion@2x": _temp16,
  "product-img-nurofit-premiere@2x": _temp17,
  "product-img-proton@2x": _temp18,
  "product-img-sbrt@2x": _temp19,
  "product-img-srs@2x": _temp20,
  "product-img-symphony@2x": _temp21,
  "product-img-vacuum-cushions@2x": _temp22,
  "symphony-slate-device@2x": _temp23
}